import React, { useState } from "react"
import Contact from "./Contact"
import projects from "../Components/Projects"
import { FaGithubAlt, FaInternetExplorer } from "react-icons/fa6"

const allSkills = [
  "All",
  ...new Set(projects.flatMap((project) => project.skills)),
]

function Portfolio() {
  const [projectsList, setProjectsList] = useState(projects)

  const filterProjects = (skill) => {
    if (skill === "All") {
      setProjectsList(projects)
    } else {
      const filteredProjects = projects.filter((project) =>
        project.skills.includes(skill)
      )
      setProjectsList(filteredProjects)
    }
  }

  return (
    <>
      <section className='portfolio__container'>
        <h2>Portfolio</h2>
        <div className='portfolio__buttons'>
          {allSkills.map((skill) => (
            <button key={skill.id} onClick={() => filterProjects(skill)}>
              {skill}
            </button>
          ))}
        </div>
        <div className='portfolio__projects'>
          {projectsList.map((project) => (
            <div
              key={project.id}
              className={"portfolio__project-box" + " " + project.name}
            >
              <div className='project__preview'>
                <img
                  src={project.img}
                  alt={project.name}
                  classname='project__image'
                />
              </div>
              <h3 className='project__title'>{project.title}</h3>
              <p>{project.desc}</p>

              <div className='portfolio__icons'>
                <a className='project-btn ' href={project.demo} target='_blank'>
                  <button>
                    <FaInternetExplorer id='explorer' />
                  </button>
                  <h3>Demo</h3>
                </a>
                <a className='project-btn' href={project.code} target='_blank'>
                  <button>
                    <FaGithubAlt id='github' />
                  </button>
                  <h3>Code</h3>
                </a>
              </div>
            </div>
          ))}
        </div>
      </section>
      <Contact />
    </>
  )
}

export default Portfolio
