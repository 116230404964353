const projectsData = [
  {
    id: 1,
    name: "Calculator",
    title: "Responsive Calculator App",
    desc: "A responsive calculator inspired by the iOS 7 design, built using HTML5, CSS3, and pure JavaScript. It functions like a standard calculator and adjusts to any screen size. Created to practice JavaScript and programming logic, this calculator provides a sleek, interactive experience and will continue to evolve with new features.",
    skills: ["JavaScript", "HTML5", "CSS"],
    demo: "https://calculator-responsive-js.vercel.app/",
    code: "https://github.com/thodorisKall/calculator-responsive-js",
    img: "https://i.imgur.com/MKbcHSm.png",
  },
  {
    id: 2,
    name: "Momentum clone",
    title: "Momentum clone",
    desc: "This Momentum clone is a personalized browser dashboard created with TypeScript and React. It greets users, shows the current time, weather, date, and a daily quote. Inspired by the Momentum Chrome extension, it aims to boost productivity with a clean, minimalistic design. Future updates will include options to save favorite links and manage tasks directly on the dashboard",
    skills: ["React", "Typescript", "SASS"],
    demo: "https://extension-clone.vercel.app/",
    code: "https://github.com/thodorisKall/extension-clone",
    img: "https://i.imgur.com/crpxqdV.png",
  },
  {
    id: 3,
    name: "Museum",
    title: "Museum Gallery Site",
    desc: "The 'Arts and History' museum website is a responsive site showcasing four beautiful paintings from around the world. Built with Tailwind CSS, it offers a smooth, engaging experience on any device. This project highlights modern design and responsive techniques. Future updates will add interactivity with React and more content, enriching the museum’s online experience.",
    skills: ["HTML5", "CSS", "Tailwind"],
    demo: "https://thodoriskall.github.io/museum-gallery-tailwind-site/",
    code: "https://github.com/thodorisKall/museum-gallery-tailwind-site",
    img: "https://i.imgur.com/GiuIBx8.png",
  },
  {
    id: 4,
    name: "Portfolio",
    title: "Web Developer Portfolio",
    desc: "It's a responsive, user-friendly site - portfolio showcasing skills, projects, and professional background. Built with HTML5, CSS3 (using BEM), and JavaScript, it provides an engaging platform for recruiters and collaborators to explore his work. The site reflects his growth as a developer and will be regularly updated with new experiences",
    skills: ["JavaScript", "HTML5", "CSS"],
    demo: "https://thodoris-web-dev-portfolio.vercel.app/",
    code: "https://github.com/thodorisKall/Thodoris-WebDev-portfolio",
    img: "https://i.imgur.com/Z5fEXIL.png",
  },
  {
    id: 5,
    name: "Pomodoro",
    title: "Pomodoro Countdown Timer",
    desc: "The App is a time management tool built with React and Next.js, designed to help users work in focused intervals with break timers. It includes 25-minute work sessions, short and long breaks, and an info section on the Pomodoro technique. With a responsive design, it’s easy to use on any device. Future updates will allow break time customization.",
    skills: ["React", "JavaScript", "NEXT", "Tailwind"],
    demo: "https://pomodoro-countdown-woad.vercel.app/",
    code: "https://github.com/thodorisKall/Pomodoro-countdown",
    img: "https://i.imgur.com/8Facr3H.png",
  },
  {
    id: 6,
    name: "Todo",
    title: "Todo List App",
    desc: "The App is a simple task manager that helps users organize their tasks. Built with React, HTML, and CSS, it allows users to add, view, check off, and delete tasks. This project reinforces core React concepts like state and components. Future updates will include task editing and a fully responsive design for all devices.",
    skills: ["React", "JavaScript", "CSS"],
    demo: "https://todo-list-livid-mu.vercel.app/",
    code: "https://github.com/thodorisKall/Todo-List",
    img: "https://i.imgur.com/NMnsvhl.png",
  },
  {
    id: 7,
    name: "Weather",
    title: "Weather Forecast App",
    desc: "The App is a user-friendly tool that lets users check current weather conditions for any city. Built with React, TypeScript, and SASS, it provides details like temperature, sky conditions, humidity, and wind speed. With a simple interface and responsive design, it’s easy to use on any device. Future updates will add historical weather data for better insights.",
    skills: ["React", "Typescript", "SASS"],
    demo: "https://weather-app-mu-five-84.vercel.app/",
    code: "https://github.com/thodorisKall/Weather-App",
    img: "https://i.imgur.com/1KZWJfJ.png",
  },
  {
    id: 8,
    name: "StreamingNow",
    title: "StreamingNow - Platform",
    desc: "StreamingNow is a movie and TV show discovery site built with Next.js, React, and Tailwind. It displays popular, on-air, and top-rated titles, with search options for finding specific movies. Users can click on any title to see details and related suggestions. Future updates will add a homepage carousel and advanced search features, enhancing the browsing experience.",
    skills: ["React", "JavaScript", "NEXT", "Tailwind"],
    demo: "https://www.streamingnow.site/",
    code: "https://github.com/thodorisKall/StreamingNow",
    img: "https://i.imgur.com/LTk30BR.png",
  },
  {
    id: 9,
    name: "BookShop",
    title: "BookShop App",
    desc: "The Web App is a simple book management tool built with React, Node.js, and Express. It allows users to view, add, update, and delete books. Designed to improve full-stack skills, it features a basic interface with React Router for easy navigation. Future updates aim to enhance the UI and add user login and registration features.",
    skills: ["React", "Node.js", "MySQL", "SASS"],
    demo: "https://i.imgur.com/05wtjqa.jpg",
    code: "https://github.com/thodorisKall/Bookshop",
    img: "https://i.imgur.com/VCLS6k3.jpg",
  },
  {
    id: 10,
    name: "Take Notes",
    title: "Take Notes App",
    desc: "The web app is a note-taking platform built with React, Node.js, and Express. It allows users to create, edit, delete, and view notes, with features like displaying recent notes and viewing all notes. The app has an intuitive interface, and future plans include enhancing the UI and deploying it online for broader access.",
    skills: ["React", "Node.js", "MySQL", "SASS"],
    demo: "https://i.imgur.com/gzV58Qw.png",
    code: "https://github.com/thodorisKall/TakeNotes",
    img: "https://i.imgur.com/dcEo2Mn.png",
  },
  {
    id: 11,
    name: "Todo List",
    title: "Todo List - React Native",
    desc: "The app is a simple task management app built with React Native. Users can easily add and delete tasks with a clean, minimalistic design. The app provides a straightforward way to manage tasks, with a future plan to add a 'done' button to mark tasks as completed, giving users visual feedback. The app is fully functional and ready for enhancements.",
    skills: ["React", "React Native"],
    demo: "https://i.imgur.com/BHkozQx.png",
    code: "https://github.com/thodorisKall/Todo-app-react-Native",
    img: "https://i.imgur.com/BHkozQx.png",
  },
]

export default projectsData
