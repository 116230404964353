import React from "react"
import { Link } from "react-router-dom"

function Header() {
  return (
    <header className='header__container'>
      <h2>Web Developer | Designing Modern and Responsive Websites</h2>
      <Link
        to='/portfolio'
        className='header-link'
        title='See All the Projects'
      >
        portfolio
      </Link>
    </header>
  )
}

export default Header
